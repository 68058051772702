/*eslint-disable*/
import React , { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Paper from '@material-ui/core/Paper';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ReseauStyle.js";

import SectionFooter from "views/footer/PageFooter.js"

import biomedal from "assets/img/partenaires/BIOMEDAL.png";
import cit from "assets/img/partenaires/Cit-Formation.png";
import grandjean from "assets/img/partenaires/Ecole-Grandjean.png";
import ecosup_hotellerie from "assets/img/partenaires/ECOSUP-HOTELLERIE.png";
import ecosup_sante from "assets/img/partenaires/ECOSUP-SANTE.png";
import esgi from "assets/img/partenaires/ESGI.png";
import formasup from "assets/img/partenaires/FORMASUPCAMPUS.png";
import ican from "assets/img/partenaires/ICAN.png";
import cfi from "assets/img/partenaires/logo-cfi-formation.jpg";
import erudis from "assets/img/partenaires/logo-erudis-formation.jpg";
import ifc from "assets/img/partenaires/logo-ifc-loire.jpg";
import interior from "assets/img/partenaires/logo-interfor.jpg";

import fnep from "assets/img/partenaires/FNEP.png";
import fede from "assets/img/partenaires/FEDE.png";
import cosep from "assets/img/partenaires/COSEP.png";

import logo_esad from "assets/img/partenaires/logo-esad.jpg";
import logo_vidal_formation from "assets/img/partenaires/logo-vidal-formation.jpg";
import logo_ecole_dentaire_francaise from "assets/img/partenaires/logo-ecole-dentaire-francaise.jpg";
import logo_supveto from "assets/img/partenaires/logo-supveto.jpg";
import logo_aepe from "assets/img/partenaires/aepe.jpg";
import logo_prepa_vidal from "assets/img/partenaires/logo-prepa-vidal.jpg";
import logo_sple from "assets/img/partenaires/logo-sple.jpg";
import logo_ifd from "assets/img/partenaires/logo-ifd.jpg";
import logo_art_dance_international from "assets/img/partenaires/logo-art-dance-international.jpg";
import logo_epth from "assets/img/partenaires/logo-epth.jpg";
import logo_wibs from "assets/img/partenaires/logo-wibs.jpg";
import logo_formatives_network from "assets/img/partenaires/logo_formatives-network.png";
import logo_isv from "assets/img/partenaires/logo-isv.jpg";
import logo_esa_toulouse from "assets/img/partenaires/logo-esa-toulouse.jpg";
import logo_ecole_vidal from "assets/img/partenaires/logo-ecole-vidal.jpg";

import ChoroplethMap from 'components/Map/ChoroplethMap';

import { Link } from "react-router-dom";

import logo_ecosup from "assets/img/ecosup.png"

const list_esad = [
                  {city:'Paris',radius:10,fillKey: 'ESAD',adresse:'18 rue Leon Frot 75011 Paris',phone:'01 40 30 05 05',email:'paris@esad-dentaire.com',link:'https://esad-dentaire.com/les-centres-esad-paris/#',latitude:48.8566,longitude:2.3522},
                  {city:'Neuilly-Sur-Seine',radius:10,fillKey: 'ESAD',adresse:'7 rue Boutard 92200 Neuilly-sur-Seine',phone:'01 81 93 63 63',email:'neuilly@esad-dentaire.com',link:'https://esad-dentaire.com/les-centres-esad-neuilly/',latitude:48.9848,longitude:2.168},
                  {city:'Toulouse',radius:10,fillKey: 'ESAD',adresse:'21 chemin de la pelude 31400 Toulouse',phone:'05 62 26 02 57',email:'toulouse@esad-dentaire.com',link:'https://esad-dentaire.com/les-centres-esad-toulouse/',latitude:43.6047,longitude:1.4442},
                  {city:'Beziers',radius:10,fillKey: 'ESAD',adresse:'17 quai du Port Neuf, 34500 Béziers',phone:'04 67 39 90 70',email:'beziers@esad-dentaire.com',link:'https://esad-dentaire.com/les-centres-esad-beziers/',latitude:43.3442,longitude:3.2158},
                  {city:'Toulon',radius:10,fillKey: 'ESAD',adresse:'177 boulevard du docteur Charles Barnier  83000 Toulon',phone:'04 94 22 59 29',email:'contact@ecosup-toulon.fr',link:'https://ecosup-toulon.fr',latitude:43.1167,longitude:5.9333},
                  {city:'Ajaccio',radius:10,fillKey: 'ESAD',adresse:'Centre Commercial Acqualonga – Route de Mezzavia 20090 Ajaccio',phone:'07 80 90 70 81',email:'ajaccio@esad-dentaire.com',link:'https://esad-dentaire.com/devenez-assistante-dentaire-a-ajaccio/',latitude:41.9192,longitude:8.7386},
                  {city:'La Réunion',radius:10,fillKey: 'ESAD',adresse:'2 Avenue Laurent Verges 97432 Ravine des Cabris',phone:'06 93 51 62 60',email:'saintpierre@esad-dentaire.com',link:'https://esad-dentaire.com/les-centres-esad-la-reunion/',latitude:42,longitude:-4},
                  {city:'Pau',radius:10,fillKey: 'ESAD',adresse:'9 rue Montpensier 64000 Pau',phone:'07 80 90 69 96',email:'pau@esad-dentaire.com',link:'https://esad-dentaire.com/les-centres-esad-pau/',latitude:43.2951,longitude:0.3708}
                  ];

const list_vidal = [
                  {city:'Toulouse',radius:10,fillKey: 'VIDAL',adresse:'11 rue du 11 novembre 1918 - Toulouse – Métro Arènes',phone:'05 61 61 51 51',email:'recrutement@vidal-formation.fr',link:'https://www.vidal-formation.fr',latitude:43.6047,longitude:1.4442},
                  {city:'Paris',radius:10,fillKey: 'VIDAL',adresse:'1 boulevard bourdon 75004 PARIS',phone:'01 77 62 44 00',email:'recrutement@vidal-formation.paris',link:'https://www.vidal-formation.fr',latitude:48.8566,longitude:2.3522},
                  {city:'Toulon',radius:10,fillKey: 'VIDAL',adresse:'177 boulevard du docteur Charles Barnier  83000 Toulon',phone:'07 80 90 69 95',email:'',link:'https://ecosuptoulon.fr',latitude:43.1167,longitude:5.9333},
                  {city:'Angoulême',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.ccicharente-formation.fr/',latitude:45.6484,longitude:0.1562},
                  {city:'Avignon',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://sante.erudis.pro/details-formation+secretaire+medicale+smed+en+partenariat+avec+vidal+formation-328.html',latitude:43.9493,longitude:4.8055},
                  {city:'Besançon',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://biomedal-formation.fr/',latitude:47.15,longitude:6.00},
                  {city:'Bézier',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.ecoleruffel.com/',latitude:43.3442,longitude:3.2158},
                  {city:'Clermont Ferrand',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.cfiformation.fr/',latitude:45.777,longitude:3.087},
                  {city:'Lyon',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'http://www.cesame.net/',latitude:45.763,longitude:4.834},
                  {city:'Metz',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.ce2p.fr/obtenez-le-titre-de-secretaire-medicale-titre-certifie-par-letat-de-niveau-iv/',latitude:49.119,longitude:6.175},
                  {city:'Macon',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.2sformations.com/',latitude:46.3,longitude:4.833},
                  {city:'Nice',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.vidal-nice.fr/',latitude:43.710,longitude:7.262},
                  {city:'Perpignan',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.formsecmed.fr/',latitude:42.697,longitude:2.8953},
                  {city:'Lille',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.vidal-formation.fr',latitude:50.63297,longitude:3.05858},
                  {city:'Amiens',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://interfor-formations.fr/',latitude:49.8941,longitude:2.2958},
                  {city:'Strasbourg',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://ecoledecommerce.com/',latitude:48.5734,longitude:7.7521},
                  {city:'Annecy',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.palafis.fr/',latitude:45.8992,longitude:6.1294},
                  {city:'Saint Etienne',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.ifc.fr/etablissement-formation/saint-etienne/',latitude:45.4397,longitude:4.3872},
                  {city:'Aix',radius:10,fillKey: 'VIDAL',adresse:'',phone:'',email:'',link:'https://www.vidal-aix.fr/',latitude:43.5297,longitude:5.4474},
                  ];

const list_supveto = [
                  {city:'Neuilly-Sur-Seine',radius:10,fillKey: 'SUPVETO',adresse:'7 rue Boutard 92200 Neuilly-Sur-Seine',phone:'01 81 93 63 63',email:'',link:'https://www.supveto-paris.fr/',latitude:48.8848,longitude:2.2685},
                  {city:'Aix en Provence',radius:10,fillKey: 'SUPVETO',adresse:'100 rue Pierre Duhem 13290 AIX EN PROVENCE',phone:'04.42.24.49.49',email:'contact.aix@erudis.pro',link:'https://www.supveto-paris.fr/',latitude:43.5283,longitude:5.4497},
                  {city:'Angers',radius:10,fillKey: 'SUPVETO',adresse:'Guinezer Chemin du pont 49070 Beaucouzé ',phone:'02 41 86 70 09',email:'',link:'https://angers.cps-ecoles.com/formation-diplomante/medical/assistante-veterinaire/',latitude:47.4712,longitude:-0.5518},
                  {city:'Avignon',radius:10,fillKey: 'SUPVETO',adresse:'95 C Allée Camille Claudel 84140 Avignon-Montfavet',phone:'04 90 82 15 00',email:'',link:'https://sante.erudis.pro/formation-veterinaire-1.html',latitude:43.9493,longitude:4.8055},
                  {city:'Besançon',radius:10,fillKey: 'SUPVETO',adresse:'',phone:'',email:'',link:'',latitude:47.25,longitude:6.0333},
                  {city:'Bordeaux',radius:10,fillKey: 'SUPVETO',adresse:'89 quai des Chartrons 33300 Bordeaux',phone:'05 56 34 01 73',email:'',link:'https://www.isvm.com/auxiliaire-de-service-veterinaire/',latitude:44.8378,longitude:-0.5667},
                  {city:'Brest',radius:10,fillKey: 'SUPVETO',radius:10,fillKey: 'SUPVETO',adresse:'6 rue Kergorju 29200 Brest',phone:'',email:'',link:'https://supveto-by-galien.fr/',latitude:48.3904,longitude:-4.4861},
                  {city:'Clermont ferrand',radius:10,fillKey: 'SUPVETO',adresse:'20 rue barrière de jaude 63000 Clermont-Ferrand',phone:'',email:'',link:'https://supveto-by-galien.fr/',latitude:45.7772,longitude:3.0870},
                  {city:'Grenoble',radius:10,fillKey: 'SUPVETO',adresse:'9 rue du Pré Cornu 38610 Gières',phone:'',email:'',link:'https://supveto-by-galien.fr/',latitude:45.1667,longitude:5.7167},                  
                  {city:'Lille',radius:10,fillKey: 'SUPVETO',adresse:'',phone:'',email:'',link:'https://www.supveto-paris.fr/',latitude:50.6292,longitude:3.0573},
                  {city:'Lyon',radius:10,fillKey: 'SUPVETO',adresse:'181-203 avenue Jean Jaurès 69007 LYON',phone:'04 37 66 48 85',email:'accueil@supveto-lyon.fr',link:'http://www.supveto-lyon.fr/',latitude:45.763420,longitude:4.834277},
                  {city:'Montpellier',radius:10,fillKey: 'SUPVETO',adresse:'300 rue Auguste Broussonnet Espace Saint Charles 34090 Montpellier',phone:'',email:'',link:'https://supveto-by-galien.fr/',latitude:43.6108,longitude:3.8767},
                  {city:'Metz',radius:10,fillKey: 'SUPVETO',adresse:'7 rue Edouard Belin 57070 METZ ',phone:'03 87 78 08 08 ',email:'info@supveto-metz.fr',link:'https://www.supveto-metz.fr/',latitude:49.1193,longitude:6.1757},
                  {city:'Nantes',radius:10,fillKey: 'SUPVETO',adresse:'4 bis rue Maryse Bastié 44 700 Orvault ',phone:'02 28 96 06 34',email:'',link:'https://nantes.cps-ecoles.com/formation-diplomante/medical/assistante-veterinaire/',latitude:47.2173,longitude:-1.5534},
                  {city:'Orléans',radius:10,fillKey: 'SUPVETO',radius:10,fillKey: 'SUPVETO',adresse:'4 rue Charles de Coulomb 45100 Orléans',phone:'02 38 42 76 71',email:'contact@supveto-centre.fr',link:'https://www.supveto-centre.fr/',latitude:47.9167,longitude:1.9},
                  {city:'Reims',radius:10,fillKey: 'SUPVETO',adresse:'165 rue de vesle 51100 Reims',phone:'',email:'',link:'https://supveto-by-galien.fr/',latitude:49.2583,longitude:4.0317},
                  {city:'Rennes',radius:10,fillKey: 'SUPVETO',adresse:'74 rue de Paris 35 000 RENNES',phone:' 02.23.21.07.07',email:'supvetorennes@gmail.com',link:'https://supveto.com/rennes',latitude:48.1173,longitude:-1.6778},
                  {city:'Rouen',radius:10,fillKey: 'SUPVETO',adresse:'24 Place Saint Marc Rouen',phone:'02 35 70 03 03',email:'',link:'https://supveto-rouen.com/',latitude:49.4431,longitude:1.0993},
                  {city:'Strasbourg',radius:10,fillKey: 'SUPVETO',adresse:'15 rue Faubourg Saverne 67000 Strasbourg',phone:'',email:'',link:'https://supveto-by-galien.fr/',latitude:48.5734,longitude:7.7521},
                  {city:'Toulouse',radius:10,fillKey: 'SUPVETO',adresse:'10 rue Sesquières 31000 Toulouse',phone:'05 34 31 66 11',email:'supveto31@orange.fr',link:'https://supveto-toulouse.fr/',latitude:43.6047,longitude:1.4442},
                  {city:'Toulon',radius:10,fillKey: 'SUPVETO',adresse:'177 boulevard du docteur Charles Barnier  83000 Toulon',phone:'07 80 90 69 95',email:'',link:'https://ecosuptoulon.fr',latitude:43.1167,longitude:5.9333},
                  {city:'Tours',radius:10,fillKey: 'SUPVETO',adresse:'38-40 Avenue Marcel Dassaults Quartier des 2 Lions 37 000 TOURS',phone:'02.38.42.76.67',email:'',link:'https://supveto.com/tours',latitude:47.3941,longitude:0.6848},
                  {city:'Saint Pierre de la Réunion',radius:10,fillKey: 'SUPVETO',adresse:'2 Avenue Laurent Verges 97432 SAINT-PIERRE',phone:'02.62.04.56.96',email:'contact@supveto.re',link:'https://www.supvetoreunion.re',latitude:42,longitude:-4},     
                  {city:'Dijon',radius:10,fillKey: 'SUPVETO',adresse:'3 All. des Bateaux Lavoirs, 21000 Dijon, France',phone:'+33 3 81 82 10 50',email:'',link:'https://www.supveto-dijon.fr',latitude:47.316667,longitude:5.01}, 
                  {city:'Lens',radius:10,fillKey: 'SUPVETO',adresse:'13 route de Béthune 62300 LENS',phone:'03.20.70.06.06',email:'contact@supveto.com',link:'https://supveto.com',latitude:50.433,longitude:2.833},  
                  {city:'Nancy',radius:10,fillKey: 'SUPVETO',adresse:'2 rue Bois Chêne Loup 54500 VANDOEUVRE-LES-NANCY',phone:'03.87.78.08.08',email:'contact@mewo.fr',link:'https://supveto.com',latitude:48.683,longitude:6.2}, 
                  {city:'Nice',radius:10,fillKey: 'SUPVETO',adresse:'694 Avenue Maurice Donat Sophia antipolis 06250 MOUGINS',phone:'04.93.17.29.30',email:'contact.nice@erudis.pro',link:'https://supveto.com',latitude:43.7,longitude:7.25},                   
                  {city:'Perpignagn',radius:10,fillKey: 'SUPVETO',adresse:'6 Impasse Cité Bartissol 66000 PERPIGNAN',phone:'04.28.95.11.35',email:'perpignan@esad-dentaire.com',link:'https://supveto.com/les-ecoles/perpignan/',latitude:42.6886591,longitude:2.8948332},                   
                  {city:'Pau',radius:10,fillKey: 'SUPVETO',adresse:'9 rue Montpensier 64000 PAU',phone:'05.59.90.23.28',email:'pau@esad-dentaire.com',link:'https://supveto.com/les-ecoles/pau/',latitude:43.299999,longitude:-0.370000},                   
               
                ];

const list_aepe = [
                  {city:'Tourcoing',radius:10,fillKey: 'AEPE',adresse:'17 Place Charles et Albert Roussel 59200 Tourcoing',phone:'03 20 70 06 06',email:'',link:'https://ecosup-campus.com',latitude:50.7250,longitude:3.1621},
                  {city:'Toulon',radius:10,fillKey: 'AEPE',adresse:'177 boulevard du docteur Charles Barnier  83000 Toulon',phone:'04 94 22 59 29',email:'contact@ecosup-toulon.fr',link:'https://ecosup-toulon.fr',latitude:43.1167,longitude:5.9333}
                  ];                 

const useStyles = makeStyles(presentationStyle);

export default function ReseauPage() {

  const data = [
      ["MB", 75], ["SK", 43], ["AB", 50], ["BC", 88], ["NU", 21], ["NT", 43],
      ["YT", 21], ["ON", 19], ["QC", 60], ["NB", 4], ["NS", 44], ["NF", 38],
      ["PE", 67]
    ];

  const [lat, setLat] = useState(46.8)
  const [lng, setLng] = useState(2.)
  const [zoom, setZoom] = useState(13);
  const position = [lat, lng];

  const [selectedEnabled, setSelectedEnabled] = React.useState(0);
  const [listToPlot,setListToPlot]=React.useState(list_esad);

  const [alignment, setAlignment] = React.useState('esad');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    if(newAlignment==='esad'){
      setListToPlot(list_esad);
    }
    if(newAlignment==='vidal'){
      setListToPlot(list_vidal);
    }
    if(newAlignment==='supveto'){
      setListToPlot(list_supveto);
    }
    if(newAlignment==='aepe'){
      setListToPlot(list_aepe);
    }
    console.log(newAlignment);
    event.preventDefault();
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/network.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.titleTop}>
                      Le réseau national
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer direction="row" justify="center" alignItems="center">
              <GridItem xs={12} sm={8} md={8}>
            <h3 className={classes.title}>Nos centres de formation répartis sur tout le territoire </h3>
            <p className={classes.descriptionJustify}>
              Produit de la rencontre fructueuse entre des pédagogues et des professionnels, les formations <b>ESAD</b>, <b>Vidal</b> et <b>SUPVETO</b> font office de référence dans leur domaine.
              Depuis leur création, de nombreuses écoles de France ont rejoint le réseau afin de proposer cette formation très professionnelle dans d’autres régions.</p>
            </GridItem>
          </GridContainer>
            <GridContainer direction="row" justify="center" alignItems="center">
              <GridItem xs={12} sm={5} md={5}>
            <h3 className={classes.title}>Nos autres centres de formation</h3>
            <p className={classes.descriptionJustify}>Cliquez sur le formation de votre choix pour afficher les différents centres sur la carte. Puis, cliquez sur un centre pour accéder au site correspondant.</p>
            <br/>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="esad" aria-label="left aligned" >
                  <img
                    src={logo_esad} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                  />
                </ToggleButton>
                <ToggleButton value="vidal" aria-label="centered">
                  <img
                    src={logo_vidal_formation} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                  />
                </ToggleButton>
                <ToggleButton value="supveto" aria-label="center">
                  <img
                    src={logo_supveto} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                  />
                </ToggleButton>
              </ToggleButtonGroup>
              </GridItem>
              <GridItem xs={12} sm={5} md={5}>
                    <div style={{
                    height:"30vh",
                    width:"100%"
                    }}>
                    <ChoroplethMap data={data} list_data={listToPlot}/>
                    </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>
          <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={5} md={5}>
                <h3 className={classes.title}>Le Réseau Ecoles</h3>
                <p className={classes.descriptionJustify}>Le réseau des écoles associées rassemble des institutions éducatives dans toute la France autour d'un objectif commun: proposer un projet pédagogique fondé sur l'interaction entre les étudiants et les enseignants professionnels et spécialistes de leur matière.</p>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_esad} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_vidal_formation} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_supveto} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_ecole_dentaire_francaise} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_prepa_vidal} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_sple} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_ifd} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_art_dance_international} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_epth} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_wibs} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_formatives_network} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_isv} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_esa_toulouse} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={logo_ecole_vidal} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
          </div>
          <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={5} md={5}>
                <h3 className={classes.title}>Partenaires institutionnels</h3>
                <p className={classes.descriptionJustify}>Les partenaires institutionnels jouent un rôle essentiel au sein de notre école. Engagés sur plusieurs années, ils soutiennent les différentes missions d'EcoSup dans leur globalité</p>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={fnep} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={fede} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={cosep} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
          </div>
          <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={5} md={5}>
                <h3 className={classes.title}>Ecoles partenaires</h3>
                <p className={classes.descriptionJustify}>EcoSup Toulon compte plus d'une douzaine d'école partenaire partout en France, proposant un panel de formation complet. </p>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={biomedal} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={cit} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={grandjean} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={ecosup_hotellerie} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={ecosup_sante} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={esgi} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={formasup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={ican} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={cfi} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={erudis} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={ifc} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <img
                      src={interior} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <SectionFooter/>
    </div>
  );
}
